import React, { useEffect } from 'react';
import { useError } from '../context/ErrorContext';
import './styles/ErrorMessage.css'; // Customize styling for the error

const ErrorMessage = () => {
  const { error, clearError } = useError();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => clearError(), 3000); // Auto-clear error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [error, clearError]);

  return error ? (
    <div className="error-message">
      <p>{error}</p>
      <div className="close-btn" onClick={clearError}>
        ✖️
      </div>
    </div>
  ) : null;
};

export default ErrorMessage;
