// src/context/ErrorContext.js

import React, { createContext, useState, useContext } from 'react';

// Create the context
const ErrorContext = createContext();

// Custom hook to use the error context
export const useError = () => useContext(ErrorContext);

// ErrorProvider component to wrap your app
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const setApiError = (message) => {
    setError(message);
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, setApiError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};
