import React from "react";
import "./styles/Pricing.css"; // Custom styles
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate()
  return (
    <section id="pricing" className="pricing-section py-5">
      <div className="container text-center">
        <h2 className="section-title mb-5">
          Flexible <span>Pricing Plans</span>
        </h2>
        <p className="section-subtitle mb-5">
          Choose a plan that fits your needs and get started with ease.
        </p>
        <div className="row g-4">
          {/* Basic Plan */}
          <div className="col-lg-4 col-md-6">
            <div className="pricing-card shadow-lg">
              <div className="pricing-header bg-gradient-basic">
                <h3>Basic</h3>
              </div>
              <div className="pricing-body">
                <h4 className="pricing-amount">$25</h4>
                <ul className="pricing-features">
                  <li>✔️ 10 Reviews</li>
                  <li>✔️ Delivered within 5 Days</li>
                  <li>✔️ 100% Real users</li>
                </ul>
                <button 
                className="btn btn-gradient"
                onClick={() => navigate("/signup")}
                >Get Started</button>
              </div>
            </div>
          </div>
          {/* Standard Plan */}
          <div className="col-lg-4 col-md-6">
            <div className="pricing-card shadow-lg">
              <div className="pricing-header bg-gradient-standard">
                <h3>Standard</h3>
              </div>
              <div className="pricing-body">
                <h4 className="pricing-amount">$50</h4>
                <ul className="pricing-features">
                  <li>✔️ 20 Reviews</li>
                  <li>✔️ Delivered based on Instructions</li>
                  <li>✔️ 100% Real users</li>
                </ul>
                <button className="btn btn-gradient"
                onClick={()=> navigate("/signup")}
                >Choose Plan</button>
              </div>
            </div>
          </div>
          {/* Premium Plan */}
          <div className="col-lg-4 col-md-6 mx-auto">
            <div className="pricing-card shadow-lg">
              <div className="pricing-header bg-gradient-premium">
                <h3>Premium</h3>
              </div>
              <div className="pricing-body">
                <h4 className="pricing-amount"><del>$125</del> $100</h4>
                <ul className="pricing-features">
                  <li>✔️ 50 Reviews</li>
                  <li>✔️ Delivered based on Instructions</li>
                  <li>✔️ 100% Real users</li>
                  <li>✔️ Custom reviews</li>
                </ul>
                <button className="btn btn-gradient"
                onClick={()=> navigate("/signup")}
                >Subscribe Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
