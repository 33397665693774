import React, { useState, useEffect } from "react";
import "./styles/Signup.css"; // Custom styles for the form
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { useAuth } from "../context/AuthContext";

const SignupForm = () => {
  const {user, googleSignup, formSignup } = useAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    method: 'signup'
  });

  useEffect(() => {
    if (user) {
      navigate("/product/google-review");
    }
    // eslint-disable-next-line
  }, [user, navigate]);

  useEffect(() => {
    /* Initialize Google Identity Services */
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your actual client ID
        callback: handleGoogleCallback,
      });

      /* Render the Google Sign-In Button */
      window.google.accounts.id.renderButton(
        document.getElementById("google-signin-button"), // Element ID
        {
          theme: "outline",
          size: "large",
          text: "signup_with",
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic (e.g., API call) here
    formSignup(formData)
  };

  const handleGoogleCallback = async (response) => {
    // Decode JWT token to get user info
    googleSignup(response?.credential, formData?.method)
    // Process user data as needed
  };

  return (
    <div className="signup-container">
      <div className="logo">
        <Logo />
      </div>
      <h2 className="signup-title">Create an Account</h2>
      <div id="google-signin-button"></div> {/* Google Sign-In Button */}
      <div className="divider">OR</div>
      <form className="signup-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your full name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email address"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Create Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Create a secure password"
            required
          />
        </div>
        <button type="submit" className="btn btn-home">
          Sign Up
        </button>
      </form>
      <div className="signup-link">
        <p>
          Already have an account?{" "}
          <Link to="/login" className="signup-link-text">
            Login
          </Link>
        </p>
      </div>
      <div className="home-link">
        <Link to="/" className="btn btn-home">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default SignupForm;
