import React, { useState } from "react";
import "./styles/BuyGoogleReview.css";
import { useOrder } from "../context/OrderContext";
import { useNavigate } from "react-router-dom";

const BuyGoogleReview = () => {
  const navigate = useNavigate()
  const { createOrder } = useOrder()
  const [numReviews, setNumReviews] = useState("");
  const [geoTarget, setGeoTarget] = useState("");
  const [customCountry, setCustomCountry] = useState("");
  const [reviewContext, setReviewContext] = useState("");
  const [customReview, setCustomReview] = useState("");
  const [googleBusinessUrl, setGoogleBusinessUrl] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [instructions, setInstructions] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!numReviews) newErrors.numReviews = "Please select the number of reviews.";
    if (!geoTarget) newErrors.geoTarget = "Please select a geo target.";
    if (geoTarget === "Custom" && !customCountry)
      newErrors.customCountry = "Please enter your country.";
    if (!reviewContext) newErrors.reviewContext = "Please select a review context.";
    if (reviewContext === "I'll write" && !customReview)
      newErrors.customReview = "Please provide your custom review.";
    if (!googleBusinessUrl)
      newErrors.googleBusinessUrl = "Please enter a valid Google Business page URL.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const formData = {
      numReviews,
      geoTarget: geoTarget === "Custom" ? customCountry : geoTarget,
      reviewContext,
      customReview: reviewContext === "I'll write" ? customReview : null,
      googleBusinessUrl,
      instructions: numReviews === "20" || numReviews === "50" ? instructions : null,
      couponCode
    };
    const order = await createOrder(formData)
    if(order?.success){
      navigate(`/product/checkout/${order?.orderId}`); 
    }
  };

  return (
    <div className="page-container">
      {/* Typography Section */}
      <div className="typography-section">
        <h1 className="typography-title">Buy Google Reviews</h1>
        <p className="typography-description">
          Enhance your business with positive, real reviews from verified users. Build a stronger reputation and grow your
          online presence today!
        </p>
        <ul className="typography-list">
          <li className="typography-list-item">✅ Verified and active profiles</li>
          <li className="typography-list-item">🌎 Global service availability</li>
          <li className="typography-list-item">🔒 100% privacy guaranteed</li>
          <li className="typography-list-item">🕒 Reviews posted over time</li>
          <li className="typography-list-item">📞 24/7 customer support</li>
          <li className="typography-list-item">💸 Bulk order discounts available</li>
        </ul>
      </div>

      {/* Form Section */}
      <div className="form-section">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Number of Reviews</label>
            <select
              className="select-field"
              value={numReviews}
              onChange={(e) => setNumReviews(e.target.value)}
            >
              <option value="">Select</option>
              <option value="10">10 Reviews - $25</option>
              <option value="20">20 Reviews - $50</option>
              <option value="50">50 Reviews - $100</option>
            </select>
            {errors.numReviews && <div className="error-message-google-review">{errors.numReviews}</div>}
          </div>

          <div className="form-group">
            <label className="form-label">Geo Target</label>
            <select
              className="select-field"
              value={geoTarget}
              onChange={(e) => setGeoTarget(e.target.value)}
            >
              <option value="">Select</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="Canada">Canada</option>
              <option value="Australia">Australia</option>
              <option value="India">India</option>
              <option value="Germany">Germany</option>
              <option value="Custom">Custom (Enter your country)</option>
            </select>
            {errors.geoTarget && <div className="error-message-google-review">{errors.geoTarget}</div>}
          </div>

          {geoTarget === "Custom" && (
            <div className="form-group">
              <label className="form-label">Enter Your Country</label>
              <input
                type="text"
                className="input-field"
                value={customCountry}
                onChange={(e) => setCustomCountry(e.target.value)}
                placeholder="Enter your country"
              />
              {errors.customCountry && <div className="error-message-google-review">{errors.customCountry}</div>}
            </div>
          )}

          <div className="form-group">
            <label className="form-label">Review Context</label>
            <select
              className="select-field"
              value={reviewContext}
              onChange={(e) => setReviewContext(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Random">Random positive reviews</option>
              <option value="I'll write">I’ll write the reviews</option>
            </select>
            {errors.reviewContext && <div className="error-message-google-review">{errors.reviewContext}</div>}
          </div>

          {reviewContext === "I'll write" && (
            <div className="form-group">
              <label className="form-label">Custom Review</label>
              <textarea
                className="textarea-field"
                value={customReview}
                onChange={(e) => setCustomReview(e.target.value)}
                placeholder="Enter your custom reviews here (seperate with commas)"
              />
              {errors.customReview && <div className="error-message-google-review">{errors.customReview}</div>}
            </div>
          )}

          <div className="form-group">
            <label className="form-label">Google Business URL</label>
            <div className="info-message-google-review">ℹ️ Where will the reviews be shared..</div>
            <input
              type="url"
              className="input-field"
              value={googleBusinessUrl}
              onChange={(e) => setGoogleBusinessUrl(e.target?.value)}
              placeholder="Enter your Google Business page URL"
            />
            {errors.googleBusinessUrl && <div className="error-message-google-review">{errors.googleBusinessUrl}</div>}
          </div>

          {(numReviews === "20" || numReviews === "50") && (
            <div className="form-group">
              <label className="form-label">Instructions (optional)</label>
              <textarea
                className="textarea-field"
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
                placeholder="Ex:- 2 orders per day, Female profiles only, One liner reviews, ..."
              />
            </div>
          )}

          <div className="form-group">
            <label className="form-label">Coupon code? (optional)</label>
            <input
              type="text"
              className="input-field"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target?.value?.toUpperCase())}
              placeholder="Enter your coupon code"
            />
            {/* {errors.googleBusinessUrl && <div className="error-message-google-review">{errors.googleBusinessUrl}</div>} */}
          </div>

          <button className="submit-button" type="submit">
            Submit Order
          </button>
        </form>
      </div>
    </div>
  );
};

export default BuyGoogleReview;
