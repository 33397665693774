import React, { useState } from "react";
import "./styles/FAQs.css";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is StarPickr?",
      answer: [
        "StarPickr is the most trusted and secure platform to enhance your online business reputation and boost your TrustScore.",
        "We help improve your Google ratings and overall online presence with 100% genuine and safe reviews.",
        "We use real user accounts to ensure that every review is authentic.",
      ],
    },
    {
      question: "Why Choose StarPickr?",
      answer: [
        "Choose StarPickr for a reliable and authentic way to boost your online presence. We focus on providing genuine reviews from real, active users, ensuring your brand gets the attention it deserves. With our strategic approach, reviews are posted gradually, making them appear natural and trustworthy. We understand the importance of privacy and offer a secure service without requiring access to your accounts. StarPickr is committed to delivering quality results with permanent reviews, affordable pricing, and a guarantee to keep your reviews intact. Let us help you build credibility and grow your business with a trusted review service.",
      ],
    },
    {
      question: "How Do We Operate?",
      answer: [
        "At StarPickr, we promise that our reviews will remain permanent, never being removed. This commitment is built on our adherence to Google’s terms and guidelines, ensuring that every review we provide is authentic, organic, and long-lasting. We recognize the importance of your business’s reputation, which is why we focus on delivering genuine, organic reviews that won’t be dropped or deleted."
      ],
    },
    {
      question: "Why Other Review Services Might Harm Your Business?",
      answer: [
        "• Suspicious Growth: If your Google My Business profile suddenly experiences an unnatural surge in reviews, it can appear suspicious to Google. This might lead to penalties or suspension, ultimately damaging your reputation and visibility.",
        "• Unnatural Flood of Positive Reviews: When a business with negative reviews suddenly receives an overwhelming amount of positive ones, it can look forced and artificial, causing Google to question the authenticity of the reviews, which could harm your rankings.",
        "• Fake Reviews from Unverified Sources: Many review services use fake or inactive accounts to generate reviews, which are easily flagged by Google. This can result in your page being penalized or suspended, negatively impacting your business's credibility and online presence.",
      ],
    },
    {
      question: "How is StarPickr Different from Other Review Services?",
      answer: [
        "✅ Focus on Authenticity: We prioritize genuine, organic reviews rather than simply filling orders for profit.",
        "💼 In-Depth Understanding of Your Brand: We study your Google My Business profile and website to understand your brand’s unique voice.",
        "🔍 Strategic Review Placement: Reviews are spaced out over time to ensure a natural growth pattern, avoiding any sudden spikes that might raise suspicions.",
        "🚀 Commitment to Long-Term Success: We aim to build your business’s credibility by delivering consistent, real reviews that reflect your brand’s values.",
        "📈 Enhancing Online Reputation: Every review is designed to positively impact your reputation and attract the right attention."
      ],
    },
    {
      question: "Can I Trust StarPickr with My Google Reviews?",
      answer: [
        "Yes! We understand the value of your business and guarantee permanent, non-drop reviews.",
        "We use a customized strategy to ensure that the reviews we post look natural, strategic, and in compliance with Google’s guidelines.",
        "Boom 💥 – StarPickr: Where genuine reviews fuel your brand’s success!"
      ],
    },
  ];

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>Frequently Asked <span>Questions?</span></h2> 
      <div className="faq-container">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleAnswer(index)}>
              <span>{faq.question}</span>
              <span className="plus-icon">
                {openIndex === index ? "−" : "+"}
              </span>
            </div>
            {openIndex === index && (
              <div
                className={`faq-answer ${openIndex === index ? "visible" : ""}`}
              >
                {faq.answer.map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default FAQs;
