import React from "react";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate()
  return (
    <section
      className="hero-section d-flex flex-column align-items-center justify-content-center text-center px-4"
      style={{
        background: "linear-gradient(135deg, #0072ff, #00c6ff)",
        minHeight: "80vh",
        overflow: "hidden",
        color: "#fff",
        position: "relative",
      }}
    >
      {/* Text Content */}
      <div
        className="hero-content d-flex flex-column justify-content-center align-items-center"
        style={{ zIndex: 1 }}
      >
        <h1
          data-aos="fade-up"
          data-aos-duration="1200"
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "600px",
            lineHeight: "1.4",
            textAlign: "center",
            textShadow: "1px 1px 5px rgba(0,0,0,0.3)",
            marginBottom: "1rem",
          }}
        >
          Boost Your Google Business with Authentic Reviews!
        </h1>
        <p
          data-aos="fade-up"
          data-aos-duration="1500"
          style={{
            fontSize: "1.2rem",
            maxWidth: "500px",
            lineHeight: "1.6",
            textAlign: "center",
            marginBottom: "1.5rem",
            textShadow: "1px 1px 3px rgba(0,0,0,0.2)",
          }}
        >
          Gain credibility and trust with real reviews that elevate your
          business profile. Let us help you stand out from the competition.
        </p>
        <button
          className="btn btn-light btn-lg"
          style={{
            padding: "12px 40px",
            borderRadius: "50px",
            fontSize: "1rem",
            fontWeight: "bold",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
          }}
          data-aos="zoom-in"
          data-aos-duration="1000"
          onClick={()=> navigate("/signup")}
        >
          Get Started
        </button>
      </div>

      {/* Background Circles */}
      <div
        className="background-circle position-absolute"
        style={{
          width: "300px",
          height: "300px",
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "50%",
          top: "10%",
          left: "10%",
        }}
      ></div>
      <div
        className="background-circle position-absolute"
        style={{
          width: "400px",
          height: "400px",
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "50%",
          bottom: "10%",
          right: "10%",
        }}
      ></div>
    </section>
  );
};

export default Hero;
