import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import "./styles/Header.css"; // Updated CSS file

const removeHeadersOnPaths = ['/signup', '/login']
const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHideHeader, setHideHeader] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    if (removeHeadersOnPaths.includes(location?.pathname)){
      setHideHeader(true)
    }
    else {
      setHideHeader(false)
    }
    // eslint-disable-next-line
  }, [location?.pathname])

  const handleNavigate = (path, hash) => {
    // Check if we are already on the same path
    if (location.pathname === path) {
      window.location.hash = "#" + hash; // Just update the hash
    } else {
      navigate(path); // Navigate to the new path
      // Add the hash only when navigating to a new path
      setTimeout(() => window.location.hash = "#" + hash, 100); // Small delay to ensure navigation is complete
    }
    setIsMenuOpen(false);
  };

  return (
    isHideHeader ? null :(
      <>
      <div></div>
    <header className="header">
        <div style={{display: "flex", "alignItems": "center"}}>
          <Logo />
        </div>
      <div className="container d-flex justify-content-between align-items-center">
      <div></div>
        <button
          className={`menu-toggle ${isMenuOpen ? "active" : ""}`}
          onClick={toggleMenu}
          aria-label="Toggle Navigation Menu"
          >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      <nav className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
        <Link to="/" className="menu-link" onClick={() => {
          setIsMenuOpen(false)
          window.scrollTo(0,0)
        }}>
          Home
        </Link>
        <Link
          to="/"
          className="menu-link"
          onClick={() => handleNavigate("/", "features")}
          >
          Features
        </Link>
        <Link
          to="/"
          className="menu-link"
          onClick={() => handleNavigate("/", "pricing")}
          >
          Pricing
        </Link>
        <Link
          to="/contact"
          className="menu-link"
          onClick={() => setIsMenuOpen(false)}
          >
          Contact
        </Link>
        <button
          className="get-started"
          onClick={() => {
            navigate("/signup")
            setIsMenuOpen(false)
          }}
          >
          Get Started
        </button>
      </nav>
    </header>
          </>
    )
  );
};

export default Header;
