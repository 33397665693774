import React from "react";
import "./styles/PrivacyPolicy.css"; // Optional custom styles

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Privacy Policy</h1>
        <p>Effective Date: November 2024</p>
        <p>
          Welcome to StarPickr! Protecting your privacy is one of our core values. This Privacy Policy outlines how we collect, use, and safeguard information related to your use of our platform. By continuing to use our services, you acknowledge and agree to the practices described herein.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect certain details when you interact with our platform. This includes, but is not limited to:
        </p>
        <ul>
          <li>
            Basic identifiers to create and manage your account.
          </li>
          <li>
            Data about how you interact with our website or app, including technical information about your device and browsing activity.
          </li>
          <li>
            Information provided during communications or transactions with StarPickr.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>Your data may be used to:</p>
        <ul>
          <li>Provide, maintain, and enhance our services.</li>
          <li>Improve user experience and platform performance.</li>
          <li>Communicate with you about updates, features, or offers.</li>
          <li>Detect and prevent fraudulent or unauthorized activities.</li>
        </ul>

        <h2>3. Sharing Information</h2>
        <p>
          We do not sell your personal data. However, we may share certain information with trusted third parties in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> To help us deliver core functionality, such as hosting, analytics, or customer support.
          </li>
          <li>
            <strong>Compliance:</strong> To meet legal or regulatory requirements.
          </li>
          <li>
            <strong>Business Changes:</strong> As part of a merger, acquisition, or other corporate restructuring.
          </li>
        </ul>

        <h2>4. Your Rights</h2>
        <p>As a user, you have certain rights regarding your data:</p>
        <ul>
          <li>Request corrections or updates to your information.</li>
          <li>Opt out of receiving promotional communications.</li>
          <li>Request deletion of data, subject to legal or operational obligations.</li>
        </ul>
        <p>
          To exercise these rights, contact us at <a href="mailto:team@starpickr.com">team@starpickr.com</a>.
        </p>

        <h2>5. Data Security</h2>
        <p>
          We implement reasonable measures to protect your information. While we strive for security, no method of storage or transmission is completely secure.
        </p>

        <h2>6. Children's Privacy</h2>
        <p>
          StarPickr is not designed for individuals under 13 years of age. We do not knowingly collect data from children. If you believe a child under 13 has shared information with us, please contact <a href="mailto:team@starpickr.com">team@starpickr.com</a>.
        </p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update this Privacy Policy periodically. Changes will take effect upon publication, and we encourage users to review the policy regularly.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have questions or concerns about this Privacy Policy or our practices, please contact us:
        </p>
        <p>
          Email: <a href="mailto:team@starpickr.com">team@starpickr.com</a>
        </p>

        <p>
          Thank you for choosing StarPickr. Your trust and privacy are our priorities.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
