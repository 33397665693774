import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import SignupForm from "./pages/Signup";
import LoginForm from "./pages/Login";
import ErrorMessage from "./components/ErrorMessage";
import BuyGoogleReview from "./pages/BuyGoogleReview";
import Paypal from "./pages/Paypal";
import Success from "./pages/Success";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AdminDashboard from "./pages/AdminDashboard";
import initGA, { logPageView } from "./utils/analytics";

const App = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
    initGA();
    logPageView();
     if (["/", "/signup", "/login"].includes(location.pathname)) {
      document.body.style.backgroundImage = "url('/background.svg')";
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.height = '100vh';
    } else {
      // Reset the styles if not on the target routes
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.style.backgroundRepeat = '';
      document.body.style.height = '';
    }

    // Cleanup function to reset the styles when the component unmounts
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.style.backgroundRepeat = '';
      document.body.style.height = '';
    };
  // eslint-disable-next-line
  }, [location.pathname])
  return (
    <>
      <ErrorMessage />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/product/google-review" element={<BuyGoogleReview />} />
        <Route path="/product/checkout/:orderId" element={<Paypal />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<div>cancel</div>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/admin" element={<AdminDashboard />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
