import React from "react";
import { AuthProvider } from "./AuthContext";
import { ErrorProvider } from "./ErrorContext";
import { OrderProvider } from "./OrderContext";

const AppProviders = ({ children }) => {
  return (
    <ErrorProvider>
      <AuthProvider>
        <OrderProvider>
          {children}
        </OrderProvider>
      </AuthProvider>
    </ErrorProvider>
  );
};

export default AppProviders;
