import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Lock } from "../assets/lock.svg";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { useOrder } from "../context/OrderContext";
import { useError } from "../context/ErrorContext";

const CheckoutForm = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const {setApiError} = useError()
  const { getOrder, sendMail } = useOrder();
  const [amount, setAmount] = useState(50);
  const [toEmail, setToEmail] = useState(null);
  const [orderData, setOrderData] = useState({})


  useEffect(() => {
    async function fetchOrder() {
      if (orderId) {
        const data = await getOrder(orderId); // Call getOrder with the orderId this.props.
        if (data?.success) {
          setAmount(data?.order?.amount);
          setToEmail(data?.order?.email);
          setOrderData(data?.order)
        }
      }
    }
    fetchOrder();
  }, [orderId]);

  const navigateSuccess = () => {
    sendMail({
      toEmail,
      subject: "Your order #" + orderId?.split("-")[1] + " has been placed!",
      emailType: "order_placed",
      data: {
        orderId: orderId?.split("-")[1],
        amount,
      },
    });
    navigate("/success", {
      state: {
        amount,
        orderId,
      },
    });
  };


  return (
    <div className="signup-container">
      <form className="checkout-form">
        <div
          style={{
            height: "40px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "5px",
          }}
        >
          <Logo />
        </div>
        {/* <h2 className="checkout-title">Complete Your Payment</h2> */}
        <p className="checkout-description">
          Secure payment with PayPal. All data is encrypted and safe.
        </p>

        <div className="form-group">
        {orderData?._id &&
        <div className="success-payment-info-box">
          <h3 className="success-payment-info-title">Order Summary</h3>
          <p className="success-payment-info-text">
            <strong>Order ID:</strong> #{orderId?.split("-")?.[1]}<br />
            <strong>Service:</strong> Google Review Boost<br />
            {
              orderData?.discount > 0 ? 
              <>
                <strong>Order amount:</strong> <del>${Number(orderData.originalAmount)?.toFixed(2)}</del><br />
                <strong className="">Coupon discount:</strong> ${orderData?.discount}<br />
                <hr/>
                <strong>Total amount payable: ${amount}</strong><br />
              </>
              : <><strong>Total amount payable: ${amount}</strong></>
            }
          </p>
        </div>
        }
        </div>


        <div className="form-group">
          <label htmlFor="card">Card Details</label>
         {
           orderData?._id && 
          <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={async () => {
            // Step 1: Create an order on the server
            const response = await axiosInstance.post(
              "/api/v1/payments/create-intent",
              {
                amount,
                orderId: orderData?._id
              }
            );
            return response.data?.orderID; // Return the order ID
          }}
          onApprove={async (data) => {
            try {
              // Step 2: Capture the payment on the server
              const response = await axiosInstance.post(
                "/api/v1/payments/pp-capture-order",
                {
                  orderId: data.orderID,
                }
              );
              if (response.data.success) {
                navigateSuccess();
              } else {
                console.error("Payment capture failed:", response.data);
              }
            } catch (error) {
              console.error("Payment error:", error);
              setApiError("Payment capture failed. Please try again.");
            } 
          }}
          onError={(err) => {
            console.error("PayPal error:", err);
            setApiError("Payment capture failed. Please try again.")
          }}
          
          options={{
            shippingPreference: "NO_SHIPPING", // No shipping address
            disableFunding: "credit,card",   // Disable credit card funding to avoid address requirements
          }}
        />
         }
        </div>
        {/* Add Stripe Icon and Encryption Message */}
        <div className="encryption-info">
          <p className="encryption-note">
            <Lock />
            Your payment is securely processed with PayPal.
          </p>
        </div>
      </form>
    </div>
  );
};

const Checkout = () => {
  return (
    <div>
     <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
        <CheckoutForm />
      </PayPalScriptProvider>
    </div>
  );
};

export default Checkout;
