import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/adminDashboard.css';
import axiosInstance from '../utils/axiosInstance';

const PasswordProtectedPage = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  const handlePasswordPrompt = () => {
    const password = prompt('Enter the admin password:');
    if (password === 'satyam123' || password === 'sourabh123') {
      setIsAuthorized(true);
    } else {
      alert('Incorrect password! Redirecting to home page.');
      navigate('/'); // Redirect to the home page
    }
  };

  React.useEffect(() => {
    if (!isAuthorized) {
      handlePasswordPrompt();
    }
    // eslint-disable-next-line 
  }, [isAuthorized]);

  return (
    <div className={isAuthorized ? "": "PasswordProtectedPage"}>
      {isAuthorized ? (
        children // Render the protected content
      ) : (
        <p>Verifying access...</p>
      )}
    </div>
  );
};

const AdminDashboard = () => {
  const [emailList, setEmailList] = useState('');
  const [subject, setSubject] = useState("Boost your business growth by 70%! 🚀 Let's make it happen.");
  const [templateId, setTemplateId] = useState('1');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const toEmails = emailList?.split(',').map((email) => email.trim());

    try {
      const res = await axiosInstance.post('/api/v1/mail/marketing', {
        toEmails,
        subject,
        templateId,
      });

      setResponse(res.data);
    } catch (error) {
      setResponse({
        success: false,
        message: error.response?.data?.message || 'Failed to send emails',
      });
    }finally {
      setLoading(false); // Set loading to false after API call finishes
    }
  };

  return (
    <PasswordProtectedPage>
      <div className="AdminDashboard">
        <h1>Email Sender</h1>
        <form className="AdminDashboard-form" onSubmit={handleSubmit}>
          <div className="AdminDashboard-field">
            <label>Email List (comma-separated):</label>
            <textarea
              className="AdminDashboard-textarea"
              value={emailList}
              onChange={(e) => setEmailList(e.target.value)}
              rows="4"
              cols="50"
              required
            />
          </div>
          <div className="AdminDashboard-field">
            <label>Subject:</label>
            <textarea
              className="AdminDashboard-input"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              rows="4"
              cols="50"
              required
            />
          </div>
          <div className="AdminDashboard-field">
            <label>Template ID:</label>
            <input
              className="AdminDashboard-input"
              type="text"
              value={templateId}
              onChange={(e) => setTemplateId(e.target.value)}
              required
            />
          </div>
          <button
            className="AdminDashboard-button"
            type="submit"
            disabled={loading} // Disable the button when loading
          >
            {loading ? 'Sending...' : 'Send Emails'} {/* Show processing text */}
          </button>
        </form>

        {response && (
          <div
            className={`AdminDashboard-response ${
              response.success ? 'success' : 'failure'
            }`}
          >
            <h2>Response:</h2>
            <p>
              <strong>Success:</strong> {response.success ? 'Yes' : 'No'}
            </p>
            <p>
              <strong>Message:</strong> {response.message}
            </p>
            {response.success && (
              <>
                <p>
                  <strong>Success Emails:</strong>{' '}
                  {response.successEmails.join(', ')}
                </p>
                <p>
                  <strong>Failed Emails:</strong>{' '}
                  {response.failedEmails.join(', ')}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </PasswordProtectedPage>
  );
};

export default AdminDashboard;
