import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useError } from "./ErrorContext";

// 1. Create Order Context
const OrderContext = createContext(null);

// 2. Provide Order Context Provider
export const OrderProvider = ({ children }) => {
  const { setApiError } = useError();
  const [loading, setLoading] = useState(false); // Handles API call states

  // 3. Create Order
  const createOrder = async (orderData) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/v1/order/create", orderData);
      setApiError(null);
      return response?.data;
    } catch (err) {
      setApiError(err?.response?.data?.msg || "Failed to create order");
    } finally {
      setLoading(false);
    }
  };

  const getOrder = async (orderId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/v1/order/get/${orderId}`);
      setApiError(null);
      return response?.data;
    } catch (err) {
      setApiError(err?.response?.data?.msg || "Failed to Get order");
    } finally {
      setLoading(false);
    }
  };

  const sendMail = async (emaildata) => {
    try {
      // setLoading(true);
      const response = await axiosInstance.post(`/api/v1/mail/send`, emaildata);
      setApiError(null);
      return response?.data;
    } catch (err) {
      // setApiError(err?.response?.data?.msg || "Failed to Get order");
    } finally {
      // setLoading(false);
    }
  };


  return (
    <OrderContext.Provider
      value={{
        loading,
        createOrder,
        getOrder,
        sendMail
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

// 6. Create Custom Hook for Convenience
export const useOrder = () => useContext(OrderContext);
