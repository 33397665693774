import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
});

// Add a request interceptor to include the token in the headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("review_buyer_auth"); // Retrieve token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach token
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle global response errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Optional: Add global error handling here
    if (error.response?.status === 401) {
      console.error("Unauthorized! Redirect to login.");
      window.location.href = "/login"
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
