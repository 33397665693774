import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const removeFooterOnPaths = ['/signup', '/login']
const Footer = () => {
  const location = useLocation()
  const [isHideFooter, setHideFooter] = useState(false)
  useEffect(() => {
    if (removeFooterOnPaths.includes(location?.pathname)){
      setHideFooter(true)
    }
    else {
      setHideFooter(false)
    }
    // eslint-disable-next-line
  }, [location?.pathname])

  return (
    isHideFooter ? null :
    <footer className="bg-dark text-white text-center py-4">
      <p className="m-0">&copy; {new Date().getFullYear()} StarPickr. All rights reserved.</p>
      <Link to="privacy-policy">Privacy Policy</Link>
    </footer>
  );
};

export default Footer;
