import React from "react";

const StatsCards = () => {
  return (
    <section
      className="stats-section d-flex flex-column align-items-center justify-content-center text-center px-4 py-5"
      style={{
        background: "#f8f9fa",
        minHeight: "50vh",
        color: "#333",
      }}
    >
      <h2
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        Key Stats
      </h2>

      <div className="stats-cards-container d-flex flex-wrap justify-content-center">
        <div
          className="stats-card card p-4 m-3 text-center"
          style={{
            width: "220px",
            background: "#fff",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease",
          }}
        >
          <h3>Happy Customers</h3>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#0072ff",
            }}
          >
            250+
          </p>
        </div>

        <div
          className="stats-card card p-4 m-3 text-center"
          style={{
            width: "220px",
            background: "#fff",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease",
          }}
        >
          <h3>Orders Processed</h3>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#0072ff",
            }}
          >
            350+
          </p>
        </div>

        <div
          className="stats-card card p-4 m-3 text-center"
          style={{
            width: "220px",
            background: "#fff",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease",
          }}
        >
          <h3>Countries Served</h3>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#0072ff",
            }}
          >
            15+
          </p>
        </div>

        <div
          className="stats-card card p-4 m-3 text-center"
          style={{
            width: "220px",
            background: "#fff",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            transition: "transform 0.3s ease",
          }}
        >
          <h3>Sales Growth</h3>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#0072ff",
            }}
          >
            35%
          </p>
        </div>
      </div>
    </section>
  );
};

export default StatsCards;
