import React from 'react';
import { ReactComponent as Logo } from "../assets/logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';

const Success = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate()
  const navigateTo = (path) => {
    navigate(path)
  };

  return (
    <div className="success-payment-container">
      <div className="success-payment-card">
        <div className="success-payment-logo-container">
          <Logo />
        </div>
        {/* Success Icon */}
        <div className="success-payment-icon-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="green"
            viewBox="0 0 24 24"
            width="64px"
            height="64px"
          >
            <path d="M10 17.49L4.5 12l1.41-1.41L10 14.67l8.09-8.09L20.5 8l-10 9.49z" />
          </svg>
        </div>

        {/* Success Message */}
        <h1 className="success-payment-title">Payment Successful!</h1>
        <p className="success-payment-description">
        Thank you for your purchase! Your transaction was successful, and
        we’re excited to start delivering your service right away.
        </p>

        {/* Order Information */}
        {state?.orderId &&
        <div className="success-payment-info-box">
          <h3 className="success-payment-info-title">Order Summary</h3>
          <p className="success-payment-info-text">
            <strong>Order ID:</strong> #{state?.orderId?.split("-")?.[1]}<br />
            <strong>Service:</strong> Google Review Boost<br />
            <strong>Amount Paid:</strong> ${state?.amount}
          </p>
        </div>
        }

        {/* Buttons */}
        <div className="success-payment-button-container">
          <button
            className="btn btn-home"
            onClick={() => navigateTo('/')}
          >
            Go to Home
          </button>
          <button
            className="btn btn-home"
            onClick={() => navigateTo('/contact')}
          >
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
