// analytics.js
import ReactGA from "react-ga4";

const initGA = () => {
  return ReactGA.initialize("G-QG8T3JHY9X"); // Replace with your measurement ID
};

export const logPageView = () => {
  ReactGA.send("pageview");
};

export default initGA;
