import React from "react";
import { ReactComponent as ReviewsIcon } from "../assets/reviews.svg";
import { ReactComponent as FastDeliveryIcon } from "../assets/fast-delivery.svg";
import { ReactComponent as AffordablePlansIcon } from "../assets/affordable-plans.svg";
import { ReactComponent as SecureIcon } from "../assets/secure.svg";

const Features = () => {
  return (
    <section id="features" className="py-3 bg-light">
      <div className="container text-center">
      <h2
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        Why You'll Love Our Service
      </h2>
      <p className="lead mb-5">
          Here's why businesses trust us to deliver real and impactful reviews:
        </p>
        <div className="row">
          <div className="col-md-3 feature-item">
            <div className="icon-wrapper">
              <ReviewsIcon className="feature-icon" />
            </div>
            <h4 className="my-3">Authentic Reviews, Guaranteed</h4>
            <p>Only verified users can leave feedback, ensuring trust and reliability.</p>
          </div>
          <div className="col-md-3 feature-item">
            <div className="icon-wrapper">
              <FastDeliveryIcon className="feature-icon" />
            </div>
            <h4 className="my-3">Strategic Review Posting</h4>
            <p>We don’t believe in bulk posting—reviews are delivered strategically over time to maximize impact.</p>
          </div>
          <div className="col-md-3 feature-item">
            <div className="icon-wrapper">
              <AffordablePlansIcon className="feature-icon" />
            </div>
            <h4 className="my-3">Affordable & Flexible Plans</h4>
            <p>We offer plans that fit your budget while meeting your unique needs.</p>
          </div>
          <div className="col-md-3 feature-item">
            <div className="icon-wrapper">
              <SecureIcon className="feature-icon" />
            </div>
            <h4 className="my-3">Privacy & Security First</h4>
            <p>We ensure top-notch encryption to protect your personal and business data.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
