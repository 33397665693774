import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState({}); // For storing validation errors
  const [loading, setLoading] = useState(false); 

  // Function to handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear errors as user types
  };

  // Function to validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters.";
    }

    return newErrors;
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    try {
      const res = await axiosInstance.post("/api/v1/support/submit", formData);
      setResponse(res.data.message);
      setFormData({ name: "", email: "", message: "" }); // Clear form after successful submission
    } catch (error) {
      setResponse(error.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false); // Re-enable the button
    }
  };

  return (
    <div className="contact-form-container">
      <h2 className="contact-form-heading">Contact Support</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="contact-form-group">
          <label htmlFor="name" className="contact-form-label">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className="contact-form-input"
          />
          {errors.name && <p className="contact-form-error">{errors.name}</p>}
        </div>
        <div className="contact-form-group">
          <label htmlFor="email" className="contact-form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            className="contact-form-input"
          />
          {errors.email && <p className="contact-form-error">{errors.email}</p>}
        </div>
        <div className="contact-form-group">
          <label htmlFor="message" className="contact-form-label">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Write your message here..."
            className="contact-form-textarea"
          ></textarea>
          {errors.message && (
            <p className="contact-form-error">{errors.message}</p>
          )}
        </div>
        <button
          className="contact-form-submit-button"
          type="submit"
          disabled={loading} // Disable the button while loading
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
      {response && <p className="contact-form-response-message">{response}</p>}
    </div>
  );
};

export default ContactForm;
