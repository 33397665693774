import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { useError } from "./ErrorContext";

// 1. Create Context
const AuthContext = createContext(null);

// 2. Provide Context Provider
export const AuthProvider = ({ children }) => {
  const { setApiError } = useError();
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false); // To handle API call states

  // 3. Google Signup
  const googleSignup = async (credentails, method) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/v1/auth/google", { credentails, method });
      const userData = response?.data?.user
      setUser(userData);
      setIsAuthenticated(true);
      setApiError(null);
      localStorage.setItem("review_buyer_auth", response?.data?.token)
    } catch (err) {
      setApiError(err?.response?.data?.msg || "Google Signup Failed");
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  // 4. Form Signup
  const formSignup = async (formdata) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/v1/auth/form", { ...formdata });
      const userData = response.data?.user
      setUser(userData);
      setIsAuthenticated(true);
      setApiError(null);
      localStorage.setItem("review_buyer_auth", response?.data?.token)
    } catch (err) {
      setApiError(err?.response?.data?.msg|| "Form Signup Failed");
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("review_buyer_auth"); // Clear token from storage
    setApiError(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        loading,
        googleSignup,
        formSignup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// 6. Create Custom Hook for Convenience
export const useAuth = () => useContext(AuthContext);
